import { MenuBar } from './modules/_Menu.es6';

(function() {
  'use strict';

  // The styling for this mobile menu is located in source/_patterns/04-components/menus/menu--main/.

  const menuNode = document.body.querySelector('.js-primary-menu > .c-menu');
  if (menuNode) {
    const myMenu = new MenuBar(menuNode);
    myMenu.init();
  }
})();
